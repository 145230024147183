<template>
  <div>
    <!-- 顶栏容器 -->
    <div class="top-bar">
      <div class="logo">
        <img src="./asset/客服-机器人.png" >
        <div style="line-height: 1.5;">
          <span style="font-size: 16px;letter-spacing: 2px;font-weight: bold;">新质智能</span>
          <br>
          <span style="font-size: 13px;letter-spacing: 1px;">专用大模型助力企业转型升级、降本增效</span>
        </div>
      </div>
      <el-menu :default-active="activeTab" class="el-menu-demo"  mode="horizontal" @select="handleSelect">
        <el-menu-item index="first"  style="margin-right: 60px;" @click="handleTabClick('first')">首页</el-menu-item>
        <el-submenu index="product"  style="margin-right: 60px;" @click="handleTabClick('second')">
          <template slot="title">产品</template>
          <el-menu-item index="codeAudit" @click="handleTabClick1('first')">代码审计</el-menu-item>
          <el-menu-item index="smartAssistant" @click="handleTabClick1('second')">智能服务助手</el-menu-item>
          <el-menu-item index="smartMarketing" @click="handleTabClick1('third')">智慧营销</el-menu-item>
          <el-menu-item index="smartAudit" @click="handleTabClick1('fourth')">智能审核</el-menu-item>
          <el-menu-item index="dangjian" @click="redirectToPage('http://www.edangjian.cn/edangjian/')">智慧政务</el-menu-item>
        </el-submenu>
        <el-menu-item index="third" style="margin-right: 60px;" @click="handleTabClick('third')">服务</el-menu-item>
<!--        <el-menu-item index="fourth" @click="handleTabClick('fourth')">渠道合作</el-menu-item>-->
        <el-menu-item index="fifth" @click="handleTabClick('fifth')">关于我们</el-menu-item>
      </el-menu>
<!--      <el-tabs v-model="activeTab"  >-->
<!--        <el-tab-pane label="首页" name="first" @click="handleTabClick('first')"></el-tab-pane>-->
<!--        <el-tab-pane label="产品" name="second">-->
<!--          <el-tabs v-model="activeTab1">-->
<!--            <el-tab-pane label="代码审计" name="first" @click="handleTabClick1('first')"></el-tab-pane>-->
<!--            <el-tab-pane label="智能服务助手" name="second" @click="handleTabClick1('second')"></el-tab-pane>-->
<!--            <el-tab-pane label="智慧营销" name="third" @click="handleTabClick1('third')"></el-tab-pane>-->
<!--            <el-tab-pane label="智能审核" name="fourth" @click="handleTabClick1('fourth')"></el-tab-pane>-->
<!--          </el-tabs>-->
<!--        </el-tab-pane>-->
<!--        <el-tab-pane label="服务" name="third" @click="handleTabClick('third')"></el-tab-pane>-->
<!--        <el-tab-pane label="渠道合作" name="fourth" @click="handleTabClick('fourth')"></el-tab-pane>-->
<!--        <el-tab-pane label="关于我们" name="fifth" @click="handleTabClick('fifth')"></el-tab-pane>-->
<!--      </el-tabs>-->

      <div class="buttons">
        <el-button>登录</el-button>
        <el-button type="primary" @click="showTrialDialog">申请试用</el-button>
        <div>
          <span class="contact-text">☎ 18971124790</span><br>
          <span class="contact-text">✉ jiaoyunhuizhi@163.com</span>
        </div>
      </div>
    </div>

    <!-- 申请试用弹窗 -->
    <el-dialog
        title="申请试用"
        :visible.sync="trialDialogVisible"
        width="30%"
        :before-close="handleClose">
      <el-form ref="trialForm" :model="trialForm" label-width="80px">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="trialForm.name"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input v-model="trialForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="trialForm.email"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="trialDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitTrialForm">提交</el-button>
      </div>
    </el-dialog>

    <!-- 主要区域容器 -->
    <div class="main-content">
<!--      &lt;!&ndash; 使用条件渲染根据不同的标签页显示不同内容 &ndash;&gt;-->
<!--      <product-introduction v-if="activeTab === 'first'">-->
<!--      <img src="./asset/banner-2.png" style=" max-width: 100%; height: auto;">-->
      <div class="slideshow-container">
        <div class="mySlides">
          <img src="./asset/banner-1.jpg" style="width: 100%;">
        </div>
        <div class="mySlides">
          <img src="./asset/banner-2.png" style="width: 100%;">
        </div>
      </div>
<!--      </product-introduction>-->
      <product-introduction v-if="activeTab === 'first'||activeTab === 'fourth' ">
<!--      <product-introduction v-if="activeTab === 'second'">-->
        <div class="centered-title" style="margin-top: 50px;">产品介绍</div>
        <div class="rightquestion">
          <ul class="product-list" >
            <li class="product-item bkg1" @click="handleTabClick1('first')">
              <div class="product-image-container">
                <img src="./asset/代码审计.png" class="product-image">
              </div>
              <div class="product-text-container">
                <div class="product-title">
                  <img src="./asset/图片5.png" class="product-icon"> <!-- 图标 -->
                  <span class="product-text">代码审计</span> <!-- 文字标题 -->
                </div>
                <div class="product-description">代码缺陷检测和自动修复一体化，大幅度提升开发效率；高准确率，解决规则引擎高误报问题；自学习，具备私有代码特征自学习能力，实现安全规范编码；插件实现开发过程安全左移</div> <!-- 文字描述 -->
              </div>

            </li>
<!--            <li class="product-item bkg2">-->
<!--              <div class="product-image-container">-->
<!--                <img src="./asset/安全知识库.png" class="product-image">-->
<!--              </div>-->
<!--              <div class="product-text-container">-->
<!--                <div class="product-title">-->
<!--                  <img src="./asset/图片5.png" class="product-icon"> &lt;!&ndash; 图标 &ndash;&gt;-->
<!--                  <span class="product-text">专用知识库</span> &lt;!&ndash; 文字标题 &ndash;&gt;-->
<!--                </div>-->
<!--                <div class="product-description">聚集各领域专用知识，构建专用的大模型，解决各领域培训、知识管理的痛点；支持私有化部署；支持向量知识库管理</div> &lt;!&ndash; 文字描述 &ndash;&gt;-->
<!--              </div>-->

<!--            </li>-->
            <li class="product-item bkg3" @click="handleTabClick1('second')">
              <div class="product-image-container">
                <img src="./asset/客服助手.png" class="product-image">
              </div>
              <div class="product-text-container">
                <div class="product-title">
                  <img src="./asset/图片5.png" class="product-icon"> <!-- 图标 -->
                  <span class="product-text">智能服务助手</span> <!-- 文字标题 -->
                </div>
                <div class="product-description">技术服务助手实现常见问题、技术咨询的智能问答，大幅度降低人工成本。</div> <!-- 文字描述 -->
              </div>
            </li>
            <li class="product-item bkg4" @click="handleTabClick1('third')">
              <div class="product-image-container">
                <img src="./asset/智慧营销.png" class="product-image">
              </div>
              <div class="product-text-container">
                <div class="product-title">
                  <img src="./asset/图片5.png" class="product-icon"> <!-- 图标 -->
                  <span class="product-text">智慧营销</span> <!-- 文字标题 -->
                </div>
                <div class="product-description">精准画像，大模型结合企业数据和行业支持实现会员精准画像；个性化推荐，主动营销，大幅度提升转化率；智能运营，降低企业成本</div> <!-- 文字描述 -->
              </div>
            </li>
            <li class="product-item bkg5" @click="handleTabClick1('fourth')">
              <div class="product-image-container">
                <img src="./asset/智能审核.png" class="product-image">
              </div>
              <div class="product-text-container">
                <div class="product-title">
                  <img src="./asset/图片5.png" class="product-icon"> <!-- 图标 -->
                  <span class="product-text">智能审核</span> <!-- 文字标题 -->
                </div>
                <div class="product-description">通过大模型解决政府、企业大量文档的审核检查，涵盖格式、语义、审核点，一站式给出审核建议。</div> <!-- 文字描述 -->
              </div>
            </li>
            <li class="product-item bkg6"  @click="redirectToPage('http://www.edangjian.cn/edangjian/')">
              <div class="product-image-container">
                <img src="./asset/智慧政务云平台.png" class="product-image">
              </div>
              <div class="product-text-container">
                <div class="product-title">
                  <img src="./asset/图片5.png" class="product-icon"> <!-- 图标 -->
                  <span class="product-text">智慧政务云平台</span> <!-- 文字标题 -->
                </div>
                <div class="product-description">智慧党建、社区管理、机关事业党委平时考核系统，让基层减负，管理智能，大幅度提升规范化和智能化水平。</div> <!-- 文字描述 -->
              </div>
            </li>
          </ul>
        </div>
<!--      </product-introduction>-->
<!--      <service-introduction v-if="activeTab === 'third'">-->
        <div class="centered-title" style="margin-top: 50px;">服务介绍</div>
        <div class="rightquestion">
          <ul class="product-list">
            <li class="product-item bkg1">
              <div class="product-image-container">
                <img src="./asset/个体效率提升.png" class="product-image">
              </div>
              <div class="product-text-container">
                <div class="product-title">
                  <img src="./asset/图片5.png" class="product-icon"> <!-- 图标 -->
                  <span class="product-text">个体效率提升</span> <!-- 文字标题 -->
                </div>
                <div class="product-description">
                  <!-- 第一行 -->
                  <div class="description-row">
                    <div class="description-column">
                      <img src="./asset/勾选.png" class="description-icon">
                      <span>需求调研</span>
                    </div>
                    <div class="description-column">
                      <img src="./asset/勾选.png" class="description-icon">
                      <span>课程定制</span>
                    </div>
                  </div>
                  <!-- 第二行 -->
                  <div class="description-row">
                    <div class="description-column">
                      <img src="./asset/勾选.png" class="description-icon">
                      <span>培训赋能</span>
                    </div>
                    <div class="description-column">
                      <img src="./asset/勾选.png" class="description-icon">
                      <span>智能员工</span>
                    </div>
                  </div>
                </div> <!-- 文字描述 -->
              </div>

            </li>
            <li class="product-item bkg2">
              <div class="product-image-container">
                <img src="./asset/关键场景优化.png" class="product-image">
              </div>
              <div class="product-text-container">
                <div class="product-title">
                  <img src="./asset/图片5.png" class="product-icon"> <!-- 图标 -->
                  <span class="product-text">关键场景优化</span> <!-- 文字标题 -->
                </div>
                <div class="product-description">
                  <!-- 第一行 -->
                  <div class="description-row">
                    <div class="description-column">
                      <img src="./asset/勾选.png" class="description-icon">
                      <span>场景识别</span>
                    </div>
                    <div class="description-column">
                      <img src="./asset/勾选.png" class="description-icon">
                      <span>智能体设计</span>
                    </div>
                  </div>
                  <!-- 第二行 -->
                  <div class="description-row">
                    <div class="description-column">
                      <img src="./asset/勾选.png" class="description-icon">
                      <span>测评调优</span>
                    </div>
                  </div>
                </div> <!-- 文字描述 -->
              </div>
            </li>
            <li class="product-item bkg3">
              <div class="product-image-container">
                <img src="./asset/业务数智化.png" class="product-image">
              </div>
              <div class="product-text-container">
                <div class="product-title">
                  <img src="./asset/图片5.png" class="product-icon"> <!-- 图标 -->
                  <span class="product-text">业务数智化</span> <!-- 文字标题 -->
                </div>
                <div class="product-description">
                  <!-- 第一行 -->
                  <div class="description-row">
                    <div class="description-column">
                      <img src="./asset/勾选.png" class="description-icon">
                      <span>运营诊断</span>
                    </div>
                    <div class="description-column">
                      <img src="./asset/勾选.png" class="description-icon">
                      <span>协同共创</span>
                    </div>
                  </div>
                  <!-- 第二行 -->
                  <div class="description-row">
                    <div class="description-column">
                      <img src="./asset/勾选.png" class="description-icon">
                      <span>建设方案</span>
                    </div>
                  </div>
                </div> <!-- 文字描述 -->
              </div>
            </li>
            <li class="product-item bkg4">
              <div class="product-image-container">
                <img src="./asset/专用大模型.png" class="product-image">
              </div>
              <div class="product-text-container">
                <div class="product-title">
                  <img src="./asset/图片5.png" class="product-icon"> <!-- 图标 -->
                  <span class="product-text">专用大模型定制</span> <!-- 文字标题 -->
                </div>
                <div class="product-description">
                  <!-- 第一行 -->
                  <div class="description-row">
                    <div class="description-column">
                      <img src="./asset/勾选.png" class="description-icon">
                      <span>数据工程</span>
                    </div>
                    <div class="description-column">
                      <img src="./asset/勾选.png" class="description-icon">
                      <span>模型调优</span>
                    </div>
                  </div>
                  <!-- 第二行 -->
                  <div class="description-row">
                    <div class="description-column">
                      <img src="./asset/勾选.png" class="description-icon">
                      <span>私有部署</span>
                    </div>
                  </div>
                </div> <!-- 文字描述 -->
              </div>
            </li>
          </ul>
        </div>

<!--      </service-introduction>-->
<!--      <capability-introduction v-if="activeTab === 'fourth'">-->
        <div class="centered-title" style="margin-top: 50px;margin-bottom: 50px;">能力介绍</div>
        <div class="component-wrapper" style="margin-bottom: 20px;">
          <!-- 第一行，一个长栅格 -->
          <el-row class="first-row" :gutter="0" style="width: 1200px; height: 40px;">
            <el-col :span="24" class="gradient-bg">
              <div class="text-center">
                <h2 class="app-title">应用软件</h2>
              </div>
            </el-col>
          </el-row>
          <!-- 第二行，5个栏栅格 -->
          <el-row class="second-row" :gutter="0"  style="width: 1200px; height: 40px;">
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">代码缺陷审计</p></div>
            </el-col>
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">安全知识库</p></div>
            </el-col>
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">智能服务助手</p></div>
            </el-col>
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">智慧营销</p></div>
            </el-col>
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">智能审核</p></div>
            </el-col>
          </el-row>
        </div>
        <div class="component-wrapper" style="margin-bottom: 20px;">
          <el-row class="first-row" :gutter="0" style="width: 1200px; height: 40px;">
            <el-col :span="24" class="gradient-bg">
              <div class="text-center">
                <h2 class="app-title">具身智能</h2>
              </div>
            </el-col>
          </el-row>
          <el-row class="second-row" :gutter="0"  style="width: 1200px; height: 40px;">
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">代码智能体</p></div>
            </el-col>
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">内容安全智能体</p></div>
            </el-col>
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">营销智能体</p></div>
            </el-col>
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">企业定制智能体</p></div>
            </el-col>
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">审核智能体</p></div>
            </el-col>
          </el-row>
        </div>
        <div class="component-wrapper" style="margin-bottom: 20px;">
          <el-row class="first-row" :gutter="0" style="width: 1200px; height: 40px;">
            <el-col :span="24" class="gradient-bg">
              <div class="text-center">
                <h2 class="app-title">大模型训练与微调</h2>
              </div>
            </el-col>
          </el-row>
          <el-row class="second-row" :gutter="0"  style="width: 1200px; height: 40px;">
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">有监督学习</p></div>
            </el-col>
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">强化学习</p></div>
            </el-col>
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">MOE多模型集成</p></div>
            </el-col>
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">知识蒸馏</p></div>
            </el-col>
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">模型加速</p></div>
            </el-col>
          </el-row>
        </div>
        <div class="component-wrapper" style="margin-bottom: 20px;">
          <el-row class="first-row" :gutter="0" style="width: 1200px; height: 40px;">
            <el-col :span="24" class="gradient-bg">
              <div class="text-center">
                <h2 class="app-title">数据工程</h2>
              </div>
            </el-col>
          </el-row>
          <el-row class="second-row" :gutter="0"  style="width: 1200px; height: 40px;">
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">数据质量过滤</p></div>
            </el-col>
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">重复数据去重</p></div>
            </el-col>
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">无结构数据处理</p></div>
            </el-col>
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">数据序列化</p></div>
            </el-col>
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">特征工程</p></div>
            </el-col>
          </el-row>
        </div>
        <div class="component-wrapper" style="margin-bottom: 20px;">
          <el-row class="first-row" :gutter="0" style="width: 1200px; height: 40px;">
            <el-col :span="24" class="gradient-bg">
              <div class="text-center">
                <h2 class="app-title">企业数据资产</h2>
              </div>
            </el-col>
          </el-row>
          <el-row class="second-row" :gutter="0"  style="width: 1200px; height: 40px;">
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">公开数据集</p></div>
            </el-col>
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">企业私有数据</p></div>
            </el-col>
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">企业数据库</p></div>
            </el-col>
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">文档非结构化数据</p></div>
            </el-col>
          </el-row>
        </div>
        <div class="component-wrapper" style="margin-bottom: 20px;">
          <!-- 第一行，一个长栅格 -->
          <el-row class="first-row" :gutter="0" style="width: 1200px; height: 40px;">
            <el-col :span="24" class="gradient-bg">
              <div class="text-center">
                <h2 class="app-title">模型库</h2>
              </div>
            </el-col>
          </el-row>
          <!-- 第二行，5个栏栅格 -->
          <el-row class="second-row" :gutter="0"  style="width: 1200px; height: 40px;">
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">通用大模型</p></div>
            </el-col>
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">开源大模型</p></div>
            </el-col>
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">模型库管理</p></div>
            </el-col>
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">深度学习</p></div>
            </el-col>
            <el-col :span="4" class="bg-light-blue">
              <div class="text-center"><p class="column-text">机器学习</p></div>
            </el-col>
          </el-row>
        </div>
      </product-introduction>

      <product-introduction v-else-if="activeTab === 'second' && activeTab1 === 'first'">
        <div >
          <product1-template></product1-template>
        </div>
      </product-introduction>
      <product-introduction v-else-if="activeTab === 'second' && activeTab1 === 'second'">
        <div >
          <product2-template></product2-template>
        </div>
      </product-introduction>
      <product-introduction v-else-if="activeTab === 'second' && activeTab1 === 'third'">
        <div >
          <product3-template></product3-template>
        </div>
      </product-introduction>
      <product-introduction v-else-if="activeTab === 'second' && activeTab1 === 'fourth'">
        <div >
          <product4-template></product4-template>
        </div>
      </product-introduction>

      <product-introduction v-else-if="activeTab === 'third'">
        <div class="provide-image-container">
          <img src="./asset/服务.jpg" >
        </div>
      </product-introduction>
      <product-introduction v-else-if="activeTab === 'fifth'">
        <div >

            <div class="single-line-component" style=" display: flex;align-items: center;justify-content: center;  margin-top: 100px;margin-bottom: 100px; ">
              <img src="./asset/右.png"  class="image" style=" height: 32px; margin: 0 40px;">
              <div class="text" style="font-size: 32px; letter-spacing: 2px;">关于我们</div>
              <img src="./asset/右.png"  class="image" style=" height: 32px; margin: 0 40px;">
            </div>

            <div class="textbox-container" style="width: 100%; display: flex;justify-content: center; align-items: center;">
              <div class="textbox" style=" width: 720px;background-color: #EBF1FF; line-height: 2;padding: 120px 180px; font-size: 22px;letter-spacing: 2px; overflow: auto; white-space: pre-wrap; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);  ">
                <div style="font-size: 26px;margin-bottom: 10px;font-weight: bold;">企业介绍：</div>
                <div style="font-size: 22px;">武汉市教云慧智信息技术有限公司是一家以专用大模型为主营业务的高科技企业，主要关注大模型在代码审计、行业知识库、智慧营销和政府审批方面的细分应用。<br><br>☑ 推出了AIsecurity代码审计系统，解决了规则引擎高误报问题；<br>☑ 智慧营销大幅度提升转化率，提质增效；<br>☑ 政府智能审核通过大模型减少人工审核工作量；<br>☑ 公司具为企业公共专用大模型训练和部署服务。<br>
                </div> <br>
                <div style="font-size: 26px;margin-bottom: 10px;font-weight: bold;">联系方式：</div>
                <div style="font-size: 22px;">电话：18971124790 / 18707153238 <br>邮箱： jiaoyunhuizhi@163.com</div>
              </div>
            </div>
        </div>
      </product-introduction>
    </div>

        <!-- 底栏容器 -->
    <div class="bottom-bar">
      <!-- 第一列 -->
      <div class="column">
        <div class="component">
          <img src="./asset/客服-机器人.png" >
          <span class="vertical-bar"></span>
          <p style="font-size: 14px; color: #757575; display: block;">专用大模型助力企业<br>转型升级、降本增效</p>
        </div>
        <div class="icons">
          <div class="icon-container">
            <img src="./asset/图标.png" @mouseover="showTooltip('18971124790')" @mouseout="hideTooltip()">
            <div class="text-tooltip tooltip" v-if="showTooltipFlag && currentTooltip === '18971124790'">18971124790</div>
          </div>
          <div class="icon-container">
            <img src="./asset/图片4.png" @mouseover="showTooltip('jiaoyunhuizhi@163.com')" @mouseout="hideTooltip()">
            <div class="text-tooltip tooltip" v-if="showTooltipFlag && currentTooltip === 'jiaoyunhuizhi@163.com'">jiaoyunhuizhi@163.com</div>
          </div>
          <div class="icon-container">
            <img src="./asset/图片6.png" @mouseover="showImageBox()" @mouseout="hideImageBox()">
            <div class="image-tooltip tooltip" v-if="showImageBoxFlag">
              <img src="./asset/公众号.jpg" style="max-width: 400px; max-height: 400px;">
            </div>
          </div>
        </div>


      </div>
      <!-- 第二列 -->
      <div class="column">
        <p class="bold-text" style="font-size: 18px;">我们的产品</p><p style="font-size: 14px;color: #757575;">代码审计</p><p style="font-size: 14px;color: #757575;">智能服务助手</p><p style="font-size: 14px;color: #757575;">智慧营销</p><p style="font-size: 14px;color: #757575;">智能审核</p><p style="font-size: 14px;color: #757575;">智慧政务云平台</p>
      </div>
      <!-- 第三列 -->
      <div class="column">
        <p class="bold-text" style="font-size: 18px;">我们的服务</p><p style="font-size: 14px;color: #757575;">私有数据处理</p><p style="font-size: 14px;color: #757575;">模型训练</p><p style="font-size: 14px;color: #757575;">大模型备案</p>
      </div>
<!--      &lt;!&ndash; 第四列 &ndash;&gt;-->
<!--      <div class="column">-->
<!--        <p class="bold-text" style="font-size: 18px;">渠道合作</p>-->

<!--      </div>-->
      <!-- 第五列 -->
      <div class="column">
        <p class="bold-text" style="font-size: 18px;">关于我们</p>

      </div>
    </div>
    <div class="divider"></div>
    <div class="image-container">
      <img src="./asset/公众号.jpg"  class="centered-image">
    </div>
    <div class="bottom-text">
      鄂ICP备18031328号-1
    </div>
  </div>
</template>



<style lang="less" scoped>
.container2 {
  width: 100%; /* 容器宽度为屏幕宽度 */
  height: 100px; /* 容器高度为600px */
  background-color: #EBF1FF; /* 背景颜色 */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* 在垂直方向上顶部对齐 */
  align-items: center;
  margin-bottom: 150px;

}
.bottom-text {
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 50px;
  text-align: center; /* 水平居中显示 */

}
.image-container {
  text-align: center; /* 图片水平居中 */
  margin-top: 10px; /* 调整顶部间距 */
}

.centered-image {
  width: 200px; /* 图片宽度 */
  height: 200px; /* 图片高度 */
  display: inline-block; /* 使图片水平居中 */
}
.divider {
  position: relative;
  width: 100%;
  height: 1px;
  background-color: #f0f0f0;
  margin-top: 25px;
  margin-bottom: 25px; /* 调整横线与文字的间距 */
}


.provide-image-container {
  width: 1200px; /* 设置容器宽度为700px */
  margin: 30px auto 0; /* 30px 的顶部外边距，然后水平居中 */
  text-align: center; /* 图片也居中显示 */
}

.provide-image-container img {
  max-width: 100%; /* 图片最大宽度为容器宽度 */
  height: auto; /* 让浏览器自动计算图片的高度，保持比例 */
}

.top-bar {
  height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  display: flex;
  align-items: center;
}

.logo img {
  width: 50px; /* 根据实际图片大小调整 */
  height: auto;
  margin-right: 20px;
  margin-left: 20px;
}

.logo span {
  font-size: 16px;
}


.buttons {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.contact-text {
  margin-left: 10px; /* 调整文字与按钮之间的间距 */
  font-size: 14px; /* 可以根据需要调整字体大小 */
  color: #999; /* 可以根据设计需求调整颜色 */
}

.centered-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.component-container {
  display: flex;
  justify-content: space-between;
}
.rightquestion {
  text-align: center; /* 居中对齐 */
  margin-top: 50px;
}

.product-list {
  display: flex;
  justify-content: center; /* 水平居中对齐 */
  margin-top: 20px; /* 顶部间距 */
}

.product-item {
  width: 220px; /* 每个产品介绍框的宽度 */
  height: 350px; /* 每个产品介绍框的高度 */
  margin-right: 20px; /* 水平间距 */
  display: flex;
  flex-direction: column; /* 确保图片和文字垂直排列 */
  //justify-content: space-between; /* 图片和文字之间的间距 */
  align-items: center; /* 水平居中对齐 */
  border-radius: 15px; /* 圆角边框 */
  background-color: rgba(128, 128, 128, 0.05); /* 透明度10%的灰色背景 */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 阴影效果 */
  padding: 20px; /* 内边距 */
}

.product-image-container {
  width: 220px; /* 图片容器的宽度 */
  height: 200px; /* 图片容器的高度 */
  display: flex;
  justify-content: center;
  align-items: flex-start; /* 图片顶部与容器顶部对齐 */
}

.product-image {
  max-width: 100%; /* 图片最大宽度为容器宽度 */
  max-height: 100%; /* 图片最大高度为容器高度 */
}

.product-text-container {
  width: 220px; /* 文字框的宽度 */
  height: 100px; /* 文字框的高度 */

}

.product-title {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px; /* 文字框之间的垂直间距 */
}

.product-icon {
  width: 20px; /* 图标宽度 */
  height: auto; /* 图标高度自适应 */
  margin-right: 10px; /* 图标和文字之间的水平间距 */
}

.product-text {
  font-size: 16px; /* 文字大小 */
}

.product-description {
  font-size: 12px; /* 描述文字大小 */
  line-height: 1.5;
  text-align: left;
  letter-spacing: 1px;
  margin-top: 20px;
}
.description-row {
  display: flex;
  justify-content: space-between; /* 左右对齐，并且元素之间的距离相等 */
  margin-top: 10px; /* 行之间的垂直间距 */
}

.description-column {
  display: flex;
  align-items: center;
}

.description-icon {
  width: 20px; /* 图标宽度 */
  height: auto; /* 图标高度自适应 */
  margin-right: 5px; /* 图标和文字之间的水平间距 */
}
.bottom-bar {
  display: flex;
  justify-content: center; /* 整体居中 */
  margin-top: 130px;
  margin-left: 150px;
}

.column {
  flex: 1;
  padding: 20px;
  //margin: 0 10px; /* 左右相隔30px */
}
.column:nth-child(1) {
  width: 150px; /* 第一列宽度为150px */
}

.column:nth-child(n+2) {
  width: 40px; /* 第二到第五列宽度为100px */
}

/* 其他样式保持不变 */


.component {
  display: flex;
  align-items: center;
}

.component img {
  width: 40px;
  height: 40px;
}

.vertical-bar {
  width: 1px;
  height: 35px;
  background-color: #000;
  margin: 0 10px;
}

.text {
  margin: 0;
}
.icons {
  display: flex;
}

.icons img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-top: 15px;
  position: relative;
}



.text-tooltip {
  width: 200px;
  height: 60px;
  background-color: #f0f0f0; /* 浅灰色背景 */
  display: none;
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  padding: 10px; /* 调整内边距 */
  border-radius: 5px;
  text-align: center; /* 文字水平居中 */
  line-height: 60px; /* 设置行高使文字垂直居中 */
  z-index: 999;
}


.image-tooltip {
  width: 180px; /* 设置图片框的固定宽度 */
  height: 180px; /* 设置图片框的固定高度 */
  background-color: #f0f0f0; /* 浅灰色背景 */
  display: none;
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  padding: 10px; /* 调整内边距 */
  border-radius: 5px;
  text-align: center; /* 图片居中 */
  z-index: 999;
  overflow: hidden; /* 隐藏溢出部分 */
}

.image-tooltip img {
  width: 100%; /* 图片宽度自适应图片框大小 */
  height: auto; /* 高度自动调整 */
  display: block; /* 确保图片居中显示 */
  margin: 0 auto; /* 图片居中 */
}


.icon-container {
  position: relative;
}

.icon-container:hover .tooltip {
  display: block;
}




.bold-text {
  font-size: 16px;
  font-weight: bold;
}

.column p {
  font-size: 12px;
  margin: 5px 0;
  line-height: 1.5;
  white-space: nowrap;
}
.component-wrapper {
  border: 1px solid #2196f3; /* 蓝色线框 */
  padding: 0px;
  width: 1200px; /* 设置组件宽度 */
  margin: 0 auto; /* 页面水平居中 */
}

.first-row .gradient-bg {
  background: linear-gradient(to right, #2196f3, #e1f5fe); /* 海军蓝到浅蓝色 */
  color: white; /* 文字颜色白色 */
  text-align: center;
  height: 40px; /* 设置行高度 */
  line-height: 40px; /* 垂直居中文本 */
}

.app-title {
  font-size: 18px;
}

.second-row {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
  text-align: center; /* 将第二行内的内容水平居中 */
  height: 30px; /* 设置行高度 */
  line-height: 40px; /* 垂直居中内容 */
}

.second-row .el-col {
  display: inline-block; /* 将 el-col 设置为内联块元素 */
  margin-right: 40px; /* 设置栅格之间的间距 */
}

.second-row .el-col:last-child {
  margin-right: 0; /* 最后一个栅格不加右边距 */
}

.bg-light-blue {
  background-color: #e1f5fe; /* 浅蓝色背景 */
  text-align: center;
}

.column-text {
  color: #000000; /* 文字颜色黑色 */
  font-size: 14px;
}
.cooperation-examples {
  width: 100%; /* 组件宽度为页面宽度 */
  height: 600px; /* 组件高度为600px */
  background-color: #2196f3; /* 背景为蓝色 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 50px;
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.title h2 {
  color: white; /* 标题文字为白色 */
}

.boxes {
  display: flex;
  justify-content: center;
}

.box {
  width: 350px;
  height: 400px;
  background-color: white;
  border-radius: 10px; /* 圆角边框 */
  margin: 0 10px; /* 每个组件框之间的间距 */
  padding: 0;
}

.box .top {
  height: 150px;
  position: relative;
}

.corner-box {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  color: white; /* 小圆角文本框文字为白色 */
  padding: 5px;
  border-radius: 5px; /* 圆角 */
}

.box img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}
.corner-box-gray {
  background-color: #e6e6e6;
  color: black;
  padding: 5px;
  border-radius: 5px;
  width: 60px;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.fixed-size {
  font-size: 12px;
  white-space: nowrap;
  width: 100%;
  margin: 0;
}

.middle {
  padding: 10px 0;
}

.bottom {
  display: flex;
  align-items: flex-start;
  margin-top: 15px;
}

.left {
  flex: 0 0 auto;
  margin-right: 10px;
}

.right {
  flex: 1;
  font-size: 14px;
  margin-right: 10px;
}
.slideshow-container {
  max-width: 100%;
  position: relative;
  margin: auto;
}

.mySlides {
  display: none;
  width: 100%;
  height: 600px; /* 设置容器的固定高度 */
}

.mySlides img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 图片填充方式，保持比例并填充容器 */
}

</style>
<script>

import product1Template from '../paperTemplate/paperTemplate.vue'; // 替换成正确的路径
import product2Template from '../newKnow/newKnow.vue'; // 替换成正确的路径
import product3Template from '../lookUptable/lookUptable.vue'; // 替换成正确的路径
import product4Template from '../modelhome/modelhome.vue'; // 替换成正确的路径
import axios from "axios";

let mymessage = {}
export default {
  name: "home",
  components: {
    product1Template,
    product2Template,
    product3Template,
    product4Template,
  },
  created() {
    // 设置页面标题为 "专用大模型"
    document.title = "专用大模型，武汉市教云慧智信息技术有限公司";

    // 设备检测和重定向逻辑
    this.detectDeviceAndRedirect();

    mymessage = {
      info: (options, single = true) => {
        this.$message({message: options, type: 'info'})
      },
      warning: (options, single = true) => {
        this.$message({message: options, type: 'warning'})
      },
      error: (options, single = true) => {
        this.$message({message: options, type: 'error'})
      },
      success: (options, single = true) => {
        this.$message({message: options, type: 'success'})
      },
    }
    this.getlist()
    sessionStorage.setItem('model',JSON.stringify(0))
  },
  mounted() {
    this.carousel(); // 在组件挂载后开始轮播
  },
  data(){
    return{
      activeTab:"first",
      activeTab1:"first",
      slideIndex: 0,
      showTooltipFlag: false,
      showImageBoxFlag: false,
      currentTooltip: '',
      trialDialogVisible: false, // 控制申请试用弹窗显示隐藏
      trialForm: {
        name: '',
        phone: '',
        email: ''
      }
    }
  },

  methods:{
    detectDeviceAndRedirect() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // 检测是否是移动设备
      var isMobile = /android|iPad|iPhone|iPod|webOS|mobile/i.test(userAgent);

      if (isMobile) {
        // 如果是移动设备，重定向到移动端页面
        window.location.href = '/homePage_mobile/#/home';
      }
    },

    redirectToPage(url) {
      // window.location.href = url;
      window.open(url, '_blank');
    },
    handleTabClick(tabName) {
      this.activeTab = tabName;
    },
    handleTabClick1(tabName) {
      this.activeTab1 = tabName;
      this.activeTab = "second";
    },
    carousel() {
      var slides = document.getElementsByClassName("mySlides");
      // 隐藏所有图片
      for (var i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      // 显示当前 slideIndex 对应的图片
      slides[this.slideIndex].style.display = "block";
      // 增加 slideIndex，确保在范围内循环
      this.slideIndex++;
      if (this.slideIndex >= slides.length) {
        this.slideIndex = 0; // 重新开始轮播
      }
      // 设置下一次轮播
      setTimeout(this.carousel.bind(this), 2500); // 设置2秒切换间隔并确保上下文正确
    },
    showTrialDialog() {
      // 显示申请试用弹窗
      this.trialDialogVisible = true;
    },
    handleClose(done) {
      // 关闭弹窗时重置表单
      this.$refs.trialForm.resetFields();
      done();
    },
    submitTrialForm() {
      const url = 'http://47.97.60.137:5000/save_to_file'; // 确保 URL 正确

      // 提交申请试用表单逻辑
      const formData = {
        name: this.trialForm.name,
        phone: this.trialForm.phone,
        email: this.trialForm.email
      };

      // 构建要写入txt文件的内容
      const textToWrite = `姓名: ${formData.name}\n联系电话: ${formData.phone}\n邮箱: ${formData.email}\n\n`;

      // 发送 POST 请求到 Flask 后端
      axios.post(url, { text: textToWrite }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
          .then((res) => {
            console.log(res);
            if (res.data.message === 'Text saved to file successfully.') {
              mymessage.success('提交成功');
            } else {
              mymessage.error('提交失败');
            }
          })
          .catch((error) => {
            console.error('Axios 错误:', error);
            mymessage.error('提交失败');
          });

      // 关闭弹窗
      this.trialDialogVisible = false;
    },



    showTooltip(content) {
      this.currentTooltip = content;
      this.showTooltipFlag = true;
    },
    hideTooltip() {
      this.showTooltipFlag = false;
    },
    showImageBox() {
      this.showImageBoxFlag = true;
    },
    hideImageBox() {
      this.showImageBoxFlag = false;
    },

  },

};
</script>